@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

html, body, #root {
  height: 100%;
}
  .App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(circle 5px at top left, rgba(226, 226, 226,0.1) 0%, rgba(226, 226, 226,0.1) 50%,rgba(201, 201, 201,0.1) 50%, rgba(201, 201, 201,0.1) 30%,transparent 30%, transparent 50%),linear-gradient(90deg, rgb(0,0,0),rgb(0,0,0));
  background-size: 32px 32px;
  font-family: "Plus Jakarta Sans", sans-serif; 
  }

  .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  color: white;
  letter-spacing: -2%;
  font-size: 14px;
}

p {
  font-size: 15px;
  letter-spacing: 2%;
  line-height: 30px;
}